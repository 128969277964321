.main {
    font-size: 24px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh - 100px);
}


.main ul {
    list-style: none;
}

.main li + li {
    margin-top: 50px;
}

.description {
    width: 60%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}








